import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Service from '@/core/services/psikologi-api/Services';
import localStorage from './localStorage'
import Swal from 'sweetalert2'
import { Fetch_MTypeTest} from "@/core/services/store/mTypeTest.module";
import { Fetch_MComboAgeScore} from "@/core/services/store/mComboAgeScore.module";
// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGINUSER = "loginuser";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      var mydata = {
        user_email: credentials.email,
        user_password: credentials.password
      };
  
      let contentType = `application/x-www-form-urlencoded`;
  
      const qs = require("qs");
  
      Service.PostData(
        ApiService,
        "login",
        qs.stringify(mydata),
        contentType,
        response => {
          resolve(response);
          if (response.status) {
            context.commit(SET_AUTH, response.data);
  


            localStorage.setLocalStorage('UserName', response.data.UserName);
            localStorage.setLocalStorage('UserToken', response.data.UserToken);
            localStorage.setLocalStorage('UserID', response.data.UserID);
            ApiService.init();
            context.dispatch(Fetch_MTypeTest)
            context.dispatch(Fetch_MComboAgeScore)
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
  
            Toast.fire({
              icon: 'success',
              title: 'Berhasil login...'
            })
          } else {
            Swal.fire({
              title: response.message,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 3000
            });
          }
        },
        err => {
          Swal.fire({
            title: "Gagal Login",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: true,
            timer: 1500
          });
          context.commit(SET_ERROR, err);
        }
      );
    });
  },
  [LOGINUSER](context, credentials) {
    return new Promise(resolve => {
      var mydata = {
        email: credentials.email,
        password: credentials.password,
        token: credentials.token
      };
  
      let contentType = `application/x-www-form-urlencoded`;
  
      const qs = require("qs");
  
      Service.PostData(
        ApiService,
        "loginparticipant",
        qs.stringify(mydata),
        contentType,
        response => {
          if(response.data.AdditionalParticipant != undefined){
            for (let x in response.data.AdditionalParticipant) {
              response.data[x] = response.data.AdditionalParticipant[x];
            } 
          }

          if(response.data.Participant != undefined){
            for (let x in response.data.Participant) {
              response.data[x] = response.data.Participant[x];
            } 
          }
          
          resolve(response);
          if (response.status) {
            context.commit(SET_AUTH, response.data);
            localStorage.setLocalStorage('ParticipantID', response.data.ParticipantID);
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
  
            Toast.fire({
              icon: 'success',
              title: 'Berhasil login...'
            })
          } else {
            Swal.fire({
              title: response.message,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 3000
            });
          }
        },
        err => {
          Swal.fire({
            title: "Gagal Login",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: true,
            timer: 1500
          });
          context.commit(SET_ERROR, err);
        }
      );
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      var mydata = {
        email: credentials.email,
        password: credentials.password,
        name: credentials.fullname,
        education_id: credentials.education,
        date_of_birth: credentials.date,
        address: credentials.address
      };
      let contentType = `application/x-www-form-urlencoded`;
  
      const qs = require("qs");
      Service.PostData(ApiService,"register", qs.stringify(mydata), contentType,
      response => {
        resolve(response);
        if (response.status) {

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'success',
            title: 'Berhasil Register...'
          })
        } else {
          Swal.fire({
            title: response.message,
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: true,
            timer: 3000
          });
        }
      },
      err => {
        Swal.fire({
          title: "Gagal Registration Error",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true,
          timer: 1500
        });
        context.commit(SET_ERROR, err);
      }
      );

    });
  },
  
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      if(context.state.user.data > 0){
        context.commit(SET_AUTH, context.state.user.data);
      }else{
        context.commit(SET_ERROR, context.state.user.error);
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
