function getRes(response) {

    var newRess = {
      data: response.data.data,
      error: response.data.error,
      status: response.data.status,
      message: response.data.message,
    };
  
    if (response.data.status) {
      newRess.data = response.data.data;
      newRess.error = response.data.error;
      newRess.status = response.data.status;
      newRess.message = response.data.message;
    } else {
      // sss
    }
  
    return newRess;
  }
  
  export default class {  
    static PostData(BaseApi, url, objData, contentType, onSuccess, onError) {
      return BaseApi.post(url, objData, contentType)
        .then(response => {
          const data = getRes(response);
          onSuccess(data);
          return data;
        })
        .catch(err => {
          onError(err);
          throw err; 
        });
    }
  
    static GetData(BaseApi, url, onSuccess, onError) {
      return BaseApi.get(url )
        .then(response => onSuccess(getRes(response)))
        .catch(err => onError(err));
    }
  
    static PutData(BaseApi, url, objData, contentType, onSuccess, onError) {
      return BaseApi.put(url, objData, contentType)
      .then(response => {
        const data = getRes(response);
        onSuccess(data);
        return data;
      })
      .catch(err => {
        if (onError && typeof onError === 'function') {
          onError(err);
        } else {
          console.error('Error during API call:', err);
        }
        throw err;
      });
    }

    static GetId(BaseApi, url, objData, contentType, onSuccess, onError) {
      return BaseApi.get(url, objData, contentType)
      .then(response => {
        const data = getRes(response);
        onSuccess(data);
        return data;
      })
      .catch(err => {
        if (onError && typeof onError === 'function') {
          onError(err);
        } else {
          console.error('Error during API call:', err);
        }
        throw err;
      });
    }
  
  
    static DeleteData(BaseApi, url,  contentType, onSuccess, onError) {
      return BaseApi.delete(url, contentType)
        .then(data => onSuccess(getRes(data)))
        .catch(err => onError(err));
    }
  }
  