import ApiService from "@/core/services/api.service";
import Service from '@/core/services/psikologi-api/Services';
import localStorage from "@/core/services/store/localStorage";

export const Fetch_MEducation            = "fetchMasterEducation";

export const SET_MASTER_Education = "setMasterEducation";
export const SET_ERROR = "setError";

const state = {
  master_Education: [],
};

const getters = {
  masterEducation(state) {
    return state.master_Education;
  }
};

const actions = {
  [Fetch_MEducation](context) {
    return new Promise((resolve, reject) => {
      Service.GetData(
          ApiService,
          `master/education`, // Append parameters to the URL
          response => {
              resolve(response.data);
              context.commit(SET_MASTER_Education, response.data);
          },
          err => {
              reject(err);
          }
      );
  });
  },
  
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_MASTER_Education](state, data) {
    state.master_Education= data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
